import React, {useEffect, useState} from "react";
import Welcome from "./components/welcome";
import "./App.css";
import {setSideBarSize} from './utils/IndexedDB'

import IframesPage from "./components/iframes-page";

const FILE_TREE_POSITION = 0
const EXTENSION_POSITION = 5

const openSideBarTab = (tabIndex: number): boolean => {
    const sidebarTabElement: HTMLElement = document.querySelector('iframe')?.contentDocument?.querySelectorAll('.actions-container')[1].children[tabIndex] as HTMLElement
    if (sidebarTabElement && !sidebarTabElement.classList.contains('checked')) {
        sidebarTabElement.click()
    }
    return Boolean(sidebarTabElement)
}

setSideBarSize()

// @ts-ignore
document.openExtensionTab = async(tabName: string): void => {
    openSideBarTab(EXTENSION_POSITION)
    await clickElementIfExists(`//li[text()='${tabName}']`, true);
}

// @ts-ignore
document.openFileTree = () => openSideBarTab(FILE_TREE_POSITION)

// @ts-ignore
document.openExtension = () => openSideBarTab(EXTENSION_POSITION)

// @ts-ignore
document.clickOnElement = (fileName) => {
    let event = new CustomEvent("clickFile", {"detail": {'fileName': fileName}})
    document.dispatchEvent(event)
}

// @ts-ignore
document.jumpTo = (fileName) => {
    let event = new CustomEvent("scrollToFile", {"detail": {'fileName': fileName}})
    document.dispatchEvent(event)
}

document.addEventListener('clickFile', async (event) => {
    // @ts-ignore
    await clickOnItem(event.detail.fileName)
})

const clickOnItem = async (fileName: string) => {
    await clickElementIfExists(`//span[text()='${fileName}']`);
}

const goToElement = async (lineNumber: string) => {
    await scrollToElementIfExist(`//div[text()='${lineNumber}']`)
}

// @ts-ignore
document.goToElement = goToElement

const getElementByXpath = (path: string, isInPlugin?:boolean) => {
    const node = !isInPlugin ? document.querySelector("iframe")?.contentDocument :
        document.querySelector('iframe')?.contentDocument?.querySelectorAll('iframe')[1]?.contentDocument?.querySelector('iframe')?.contentDocument

    return document
        .querySelector("iframe")
        ?.contentDocument?.evaluate(
            path,
            node as Node,
            null,
            XPathResult.FIRST_ORDERED_NODE_TYPE,
            null
        ).singleNodeValue;
};

const clickElementIfExists = (path: string, isInPlugin?:boolean) => {
    return waitForElementAndCallMethod(path, (node) => {
        node.click();
    }, isInPlugin);
};

const scrollDown = async (path: string) => {
    return waitForElementAndCallMethod(path,(node) => {
        console.log(node)
        node.scroll(0, 1200);
    });
};

const waitForElementAndCallMethod = (
    path: string,
    callback: (node: HTMLElement) => void,
    isInPlugin?:boolean
) => {
    return new Promise((resolve) => {
        const interval = setInterval(() => {
            const element = getElementByXpath(path, isInPlugin);
            if (element) {
                callback(element as HTMLElement);
                clearInterval(interval);
                resolve(true);
            }
        }, 1000);
    });
};

const scrollToElementIfExist = (path: string) => {
    return waitForElementAndCallMethod(path, (node) => {
        console.log(node)
        node.scrollIntoView({block: 'end'})
    });
};

const openGameCode = async () => {
    await clickElementIfExists("//span[text()='app']");
    await clickElementIfExists("//span[text()='src']");
    await clickElementIfExists("//span[text()='logic']");
    await clickElementIfExists("//span[text()='game-logic.js']");
    await clickElementIfExists("//span[text()='routes']");
};

function App() {
    const [environmentUrl, setEnvironmentUrl] = useState('')

    useEffect(() => {
        if (window.location.pathname !== "/") {
            window.location.pathname = "/";
        }
    });

    if (environmentUrl) openGameCode()

    return (
        !environmentUrl ? <Welcome setEnvironmentUrl={setEnvironmentUrl}/> :
            <IframesPage environmentVariables={environmentUrl}/>
    );
}

export default App;
