import React, {useState} from 'react'

import axios from 'axios'
import styled from "styled-components";
import {ReactComponent as CheetaIcon} from './assests/cheeta-icon.svg';
import {ReactComponent as FooterSVG} from './assests/footer.svg';
import {ReactComponent as IntelliJSVG} from './assests/intelliJ.svg';
import {ReactComponent as JavaSVG} from './assests/java.svg';
import {ReactComponent as PythonSVG} from './assests/python.svg';
import {ReactComponent as NodeSVG} from './assests/node.svg';
import {ReactComponent as WebstormSVG} from './assests/webstorm.svg';
import {ReactComponent as PyCharmSVG} from './assests/pyCharm.svg';
import {ReactComponent as VscodeSVG} from './assests/vscode.svg';
import CheckedLine from "./checked-line";
import Spinner from "./spinner";


interface Props {
    setEnvironmentUrl: (data: string) => void
}


const Welcome: React.FC<Props> = ({setEnvironmentUrl}) => {
    const [loading, setLoading] = useState(false);
    const [email, setEmail] = useState('')

    const validateEmail = (inputText: string): boolean => {
        let mailformat = /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
        return mailformat.test(inputText)
    }

    const onEmailChange = (event: React.FormEvent<HTMLInputElement>): void => {
        event.stopPropagation()
        setEmail(event.currentTarget.value)
    }

    const handleClick = (): void => {
        if (validateEmail(email)) {
            setLoading(true)
            axios.post('/api/entrypoint', email)
                .then((response) => {
                    setEnvironmentUrl(JSON.stringify(response.data))
                    // navigate(`/playground/${response.data.session_id}?userflow=${response.data.userflow_id}`);
                })
                .catch((error) => {
                    console.log(error);
                    if (error.response?.status === 405) {
                      window.location.reload();
                    } else {
                      alert(
                        "Something went wrong, please try again in few minutes."
                      );
                    }
                })
                .finally(() => {
                    setLoading(false)
                })
        } else {
            alert('sorry, this is an invalid email')
        }
    }

    return <Background>
        <ModalBackground>
            <Modal>
                {loading ? <Spinner/> :
                    <WelcomeWindow>
                        <Icon>icon</Icon>
                        <Header>Welcome To</Header>
                        <SubHeader>Lightrun's playground</SubHeader>
                        <Text>Play around with Lightrun and a real, live app. Zero configuration required.</Text>
                        <InputContainer method="post" onSubmit={e => {
                            handleClick();
                            e.preventDefault()}}>

                            <Input type={"email"} placeholder={'E-Mail'} name={"email"}
                                   onChange={(event: React.FormEvent<HTMLInputElement>) => onEmailChange(event)}
                            />
                            <Button type={"submit"}>Get started</Button>
                        </InputContainer>
                    </WelcomeWindow>}
                <FooterContainer>
                    <FooterData>
                        <div style={{width: '53%'}}></div>
                        <LinesContainer>
                            <CheckedLine text={'A real-time, read-only troubleshooting platform'}/>
                            <CheckedLine text={'Add logs, metrics and traces to live applications'}/>
                            <CheckedLine text={'Works directly in the IDE'}/>
                            <CheckedLine text={'Supports the JVM, Node.js & Python'}/>
                            <IntegrationContainer>
                                <JavaSVG/>
                                <NodeSVG/>
                                <PythonSVG/>
                                <IntelliJSVG/>
                                <WebstormSVG/>
                                <PyCharmSVG/>
                                <VscodeSVG/>
                            </IntegrationContainer>
                        </LinesContainer>
                    </FooterData>
                    <FooterBackground/>
                </FooterContainer>
            </Modal>
        </ModalBackground>
    </Background>
}

const Background = styled.div`
  width: 100vw;
  height: 100vh;
  background-color: black;
  background-image:url("/background.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
`

const IntegrationContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 25px;
  align-items: center;
`

const ModalBackground = styled.div`
  font-family: 'Inter', sans-serif;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  filter: blur();
`

const Modal = styled.div`
  width: 40%;
  min-width: 764px;
  min-height: 464px;
  height: 43%;
  background-color: #fff;
  border-radius: 8px;
`

const WelcomeWindow = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  height: 55%;
`

const Header = styled.h1`
  font-family: 'Cairo', sans-serif;
  font-size: 32px;
  font-weight: 400;
  line-height: 32px;
  letter-spacing: -0.02em;
  text-align: center;
  margin: 0;
`

const SubHeader = styled(Header)`
  font-weight: bold;
`

const Text = styled.p`
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: center;
`

const Icon = styled(CheetaIcon)`
  margin-top: 52px;
  margin-bottom: 2px;
`

const FooterBackground = styled(FooterSVG)`
  height: 100%;
  width: 100%;
  z-index: -1;
`

const Input = styled.input`
  width: 300px;
  height: 33px;
  border-radius: 7px;
  border: 1px solid teal;
  margin-right: 17px;
  padding: 0 13px;
  font-size: 14px;
  line-height: 130%;
  letter-spacing: -0.02em;
  color: #828282;
`

const FooterContainer = styled.div`
  position: relative;
  width: 100.3%;
  left: -1px;
  //need to fix those ugly numbers.. quick fix due to wrong svg size.
`

const Button = styled.button`
  font-family: 'Inter', sans-serif;
  font-weight: 600;
  width: 124px;
  height: 33px;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), linear-gradient(287.22deg, #00F2FF 11.79%, #5484D6 77.96%);
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 7px;
  font-size: 14px;
  color: #fff;
  border: none;
`

const InputContainer = styled.form`
  display: flex;
`

const FooterData = styled.div`
  display: flex;
  position: absolute;
  height: 100%;
  width: 100%;
`

const LinesContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
`


export default Welcome