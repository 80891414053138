import React from "react";
import userflow from "userflow.js";
import {useParams} from 'react-router-dom'


interface Props {
  environmentVariables: string
}

const IframesPage:React.FC<Props> = ({environmentVariables})=> {
    
    const {session_id, userflow_id} = JSON.parse(environmentVariables)

    userflow.init("ct_2cr2apkujvh7xag4ezserzr6ey");
    userflow.identify(`${session_id}@lightrun-playground.com`);

    userflow.start(userflow_id);



    return <div style={{display: "flex"}}>
        <div className="App" style={{height: "100vh", width: "100%"}}>
            <iframe
                src={`/playground/${session_id}/code/?folder=/home/coder/project`}
                width="100%"
                height="100%"
                title="Code server"
            />
        </div>
        <div className="App" style={{height: "100vh", width: "60%"}}>
            <iframe
                src={`/playground/${session_id}/app/`}
                width="100%"
                height="100%"
                title="Code server"
            />
        </div>
    </div>
}



export default  IframesPage