import React from "react";
import styled from "styled-components";
import {ReactComponent as Checked} from "./assests/checked.svg";

interface Props {
    text: string
}

const CheckedLine: React.FC<Props> = ({text}) => {
    return <LineContainer>
        <CheckedIcon/>
        <Text>{text}</Text>
    </LineContainer>
}

const LineContainer = styled.div`
  display: flex;
  align-items: center;
`

const CheckedIcon = styled(Checked)`
  width: 11.5px;
`

const Text = styled.p`
  font-size: 14px;
  line-height: 130%;
  letter-spacing: -0.02em;
  color: #FFFFFF;
  margin:3px 13.5px;
`

export default CheckedLine;