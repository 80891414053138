const GLOBAL_PLUGIN_SIZE = "300"
const GLOBAL_PLUGIN_SIZE_KEY = "workbench.sideBar.size"

export const setSideBarSize = ():void =>{
    const indexedDB = window.indexedDB || window.mozIndexedDB || window.webkitIndexedDB || window.msIndexedDB || window.shimIndexedDB;
    const open = indexedDB.open("vscode-web-state-db-global", 1);

    // Create the schema
    open.onupgradeneeded = function() {
        var db = open.result;
        var store = db.createObjectStore("ItemTable");
    };

    open.onsuccess = function() {
        // Start a new transaction
        var db = open.result;
        var transaction = db.transaction("ItemTable", "readwrite");
        var store = transaction.objectStore("ItemTable");

        // Add the key that controls the extension width and set it to the correct value (300)
        store.put(GLOBAL_PLUGIN_SIZE, GLOBAL_PLUGIN_SIZE_KEY);

        // Close the db when the transaction is done
        transaction.oncomplete = function() {
            db.close();
        };
    }
}
